<template>
    <a-layout class="custom-layout">
      <a-layout-sider class="custom-sider">
        <system-menu></system-menu>
      </a-layout-sider>
      <a-layout-content class="custom-content auto" style="position:relative;">
        <a-spin :spinning="loading">
          <div class="topsubmenu auto">
            <a-radio-group default-value="3" size="small" @change="changeSubmenu" button-style="solid">
                <a-radio-button value="1">
                消息提醒
                </a-radio-button>
                <a-radio-button value="2">
                定时任务
                </a-radio-button>
                <a-radio-button value="3">
                登录黑名单
                </a-radio-button>
            </a-radio-group>
          </div>
          <div class="content-title auto">
            <div class="coustom-title fl">登录黑名单（{{countData.lock}}人）</div>
          </div>
            
          <div class="coustom-title-mes auto">
            <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />一共有登录黑名单 {{countData.lock}} 人
          </div>
          <div class="content-bar auto">
            <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
              <div slot="custom">
                <!-- <a-button type="primary" size="small" class="m-r-5 fl" @click="addAttr"> 添加字段 </a-button> -->
                <a-input-search type="text" v-model="filter.schoolid" size="small" placeholder="请输入学工号" class="m-r-5 fl" style="width:140px;" allowClear @search="onSearch"></a-input-search>
              </div>
            </custom-page>
          </div>
          <div class="content-table auto">
            <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" @change="onTableChange" size="middle" :pagination="false">
              <div slot="tableindex" slot-scope="text,record,index">
                {{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}
              </div>
              <div slot="department" slot-scope="text">
                <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}<span v-show="index !== text.length - 1">，</span></span>
              </div>
              <div slot="type" slot-scope="text">
                {{usertypeName[text]}}
              </div>
              <div slot="action" slot-scope="text, record">
                <a-popconfirm
                  title="确定要删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  placement="topRight"
                  @confirm="deleteAttr(record)"
                >
                  <a-button type="primary" size="small" class="bgred borred">删除</a-button>
                </a-popconfirm>
              </div>
            </a-table>
          </div>
          <div class="content-bar auto">
            <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
              <div slot="custom">
                <!-- <a-button type="primary" size="small" class="m-r-5 fl" @click="addAttr"> 添加字段 </a-button> -->
                <a-input-search type="text" v-model="filter.schoolid" size="small" placeholder="请输入学工号" class="m-r-5 fl" style="width:140px;" allowClear @search="onSearch"></a-input-search>
              </div>
            </custom-page>
          </div>
        </a-spin>
      </a-layout-content>
      
      <a-modal v-model="addVisible" :title="isEdit ? '编辑字段' : '添加字段'" width="600px" @cancel="addClose">
        <a-form-model
          ref="addForm"
          :model="form"
          :rules="formRules"
          :label-col="{span: 5}"
          :wrapper-col="{span: 19}"
          class="ant-form-space-20"
        >
          <a-form-model-item label="字段类别" prop="category" v-if="!isEdit">
            <a-radio-group v-model="form.category">
              <a-radio value="EXTEND">
                扩展字段
              </a-radio>
              <a-radio value="DERIVED">
                派生字段
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="字段名" prop="nameEn">
            <template v-if="form.category !== 'DERIVED'">
              <a-input v-model="form.nameEn" placeholder="请输入字段名" size="small" style="width: 312px;" v-if="!isEdit"></a-input>
              <span v-else>{{ form.nameEn }}</span>
            </template>
            <template v-else>
              <a-select v-model="form.nameEn" placeholder="请选择字段名" size="small" style="width: 312px;" v-if="!isEdit">
                <a-select-option :value="item.id" v-for="(item, index) in filters['derived']" :key="index">
                  {{item.name}}
                </a-select-option>
              </a-select>
              <span v-else>{{ form.nameEn }}</span>
            </template>
          </a-form-model-item>
          <a-form-model-item label="字段类别" prop="category" v-if="isEdit">
            {{ filters['category'].find(item => {return item.id === form.category})['name'] }}
          </a-form-model-item>
          <a-form-model-item label="显示名称" prop="nameZh">
            <a-input v-model="form.nameZh" placeholder="请输入显示名称" size="small" style="width: 312px;"></a-input>
          </a-form-model-item>
          <a-form-model-item label="字段类型" prop="type" v-if="form.category !== 'DERIVED'">
            <a-select v-model="form.type" placeholder="请选择字段类型" size="small" style="width: 312px;" v-if="!isEdit">
              <a-select-option :value="item.id" v-for="(item, index) in filters['type']" :key="index">
                {{item.name}}
              </a-select-option>
            </a-select>
            <span v-else>{{ filters['type'].find(item => {return item.id === form.type})['name'] }}</span>
          </a-form-model-item>
          <a-form-model-item label="允许编辑" prop="isEditabled" v-if="form.category !== 'DERIVED'">
            <a-switch v-model="form.isEditabled" size="small" />
          </a-form-model-item>
          <a-form-model-item label="脱敏显示" prop="desensitizationMode" v-if="form.category !== 'DERIVED'">
            <a-switch v-model="form.isDesensitization" size="small" />
            <span style="color: rgba(0, 0, 0, 0.85);margin-left: 18px;" v-if="form.isDesensitization">脱敏方式：</span>
            <a-select v-model="form.desensitizationMode" v-if="form.isDesensitization" placeholder="请选择脱敏方式" size="small" style="width: 197px;">
              <a-select-option :value="item.id" v-for="(item, index) in filters['desensitization']" :key="index">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="创建索引" prop="isIndex" v-if="form.category !== 'DERIVED'">
            <a-switch :disabled="true" v-model="form.isIndex" size="small" />
          </a-form-model-item>
          <a-form-model-item label="作为扩展属性" prop="isCasAttribute">
            <a-switch v-model="form.isCasAttribute" size="small" />
          </a-form-model-item>
          <a-form-model-item label="作为LADP属性" prop="isLdapAttribute">
            <a-switch v-model="form.isLdapAttribute" size="small" />
          </a-form-model-item>
          <a-form-model-item label="参与筛选器运算" prop="isFilterOperation" v-if="form.category !== 'DERIVED'">
            <a-switch v-model="form.isFilterOperation" size="small" />
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <a-button key="back" @click="addClose">
            取 消
          </a-button>
          <a-button key="submit" type="primary" @click="addSave">
            确 定
          </a-button>
        </template>
      </a-modal>

      <a-modal v-model="showDeleteTip" :title="'删除（' + rowDetail.nameEn + '）'" width="400px" @cancel="deleteTipClose">
        <div style="color: rgba(0,0,0,0.85);">
          <!-- 字段被占用，不能被删除， <a href="javascript:;" class="alink underline" @click="checkDetail">查看详情</a> -->
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" @click="deleteTipClose">
            不删除
          </a-button>
        </template>
      </a-modal>
      
    </a-layout>
  </template>
  
  <script>
  import { System } from '@/model'
  export default {
    data () {
      return {
        loading: true,
        filters: {},
        tablecolumns: [
          {
            title: '序号',
            dataIndex: 'tableindex',
            scopedSlots: { customRender: 'tableindex' }
          },
          {
            title: '学工号',
            dataIndex: 'schoolid',
            scopedSlots: { customRender: 'schoolid' }
          },
          {
            title: '姓名',
            dataIndex: 'name',
            scopedSlots: { customRender: 'name' }
          },
          {
            title: '所属单位',
            dataIndex: 'department',
            scopedSlots: { customRender: 'department' }
          },
          {
            title: '人员类别',
            dataIndex: 'type',
            scopedSlots: { customRender: 'type' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            customCell: () => {
              return {
                style: {
                  'width': '80px',
                }
              }
            }
          }
        ],
        tabledata: [],
        addVisible: false,
        isEdit: false,
        form: {
          nameEn: '',
          nameZh: '',
          type: 'VARCHAR',
          category: 'EXTEND',
          isIndex: false,
          isEditabled: true,
          isCasAttribute: true,
          isLdapAttribute: true,
          isFilterOperation: true,
          isDesensitization: true,
          desensitizationMode: 'id'
        },
        formRules: {
          nameEn: [{ required: true, message: '字段名不能为空', trigger: 'blur' }],
          nameZh: [{ required: true, message: '字段显示名称不能为空', trigger: 'blur' }],
          // desensitizationMode: [{ required: true, message: '脱敏方式不能为空', trigger: 'blur' }]
        },
        tablecolumns2: [
          {
            title: '系统名称',
            dataIndex: 'serviceName'
          },
          {
            title: '作为扩展属性',
            dataIndex: 'isCasAttribute',
            scopedSlots: { customRender: 'isCasAttribute' }
          },
          {
            title: '作为LDAP属性',
            dataIndex: 'isLdapAttribute',
            scopedSlots: { customRender: 'isLdapAttribute' }
          }
        ],
        servicesTableData: [],
        filter: {
          schoolid: '',
          current_page: 1,
          page_size: 10
        },
        countData: {
          lock: 0
        },
        total: 0,
        current_row: {},
        detailTableLoading: false,
        detailTableHeader: [
          {
            title: '筛选器名称',
            dataIndex: 'filterName'
          },
          {
            title: '关联标签',
            dataIndex: 'tags',
            scopedSlots: { customRender: 'tags' }
          }
        ],
        detailTableData: [],
        count3: 0,
        detailFilter: {
          current_page: 1,
          page_size: 10
        },
        rowDetail: {},
        showDeleteTip: false,
        showDetail: false,
        detailTab: 'services',
        departmentName: {},
        usertypeName: {}
      }
    },
    methods: {
      changeSubmenu (e) {
        var value = Number(e.target.value)
        console.log('value', value)
        switch (value) {
            case 1:
            this.$router.push('/system/other/message');
            break;
            case 2:
            this.$router.push('/system/other/task');
            break;
            case 3:
            this.$router.push('/system/other/authentication');
            break;
            default:
            break;
        }
      },
      addSave () {
        this.$refs['addForm'].validate( async validate => {
          if (validate) {
            this.loading = true

            let newForm = {
              nameEn: this.form.nameEn,
              nameZh: this.form.nameZh,
              type: this.form.type,
              category: this.form.category,
              isIndex: this.form.isIndex ? 1 : 0,
              isEditabled: this.form.isEditabled ? 1 : 0,
              isCasAttribute: this.form.isCasAttribute ? 1 : 0,
              isLdapAttribute: this.form.isLdapAttribute ? 1 : 0,
              isFilterOperation: this.form.isFilterOperation ? 1 : 0,
              isDesensitization: this.form.isDesensitization ? 1 : 0,
              desensitizationMode: this.form.desensitizationMode
            }

            if (this.isEdit) {
              let res = await System.putAttribute(this.current_row['id'], newForm)
              if (!res['code']) {
                this.$message.success('操作成功！')
                this.addClose()
                this.getAuthorizationUser()
              } else {
                this.$message.error(this.errMsg[res['code']])
              }
            } else {
              let res = await System.postAttribute(newForm)
              if (!res['code']) {
                this.$message.success('操作成功！')
                this.addClose()
                this.getAuthorizationUser()
              } else {
                this.$message.error(this.errMsg[res['code']])
              }
            }
          }
        })
      },
      addClose () {
        this.form = {
          nameEn: '',
          nameZh: '',
          type: 'VARCHAR',
          category: 'EXTEND',
          isIndex: false,
          isEditabled: true,
          isCasAttribute: true,
          isLdapAttribute: true,
          isFilterOperation: true,
          isDesensitization: true,
          desensitizationMode: 'id'
        }
        this.$refs['addForm'].resetFields()
        this.addVisible = false
      },
      onSearch (e) {
        this.filter.current_page = 1
        this.getAuthorizationUser()
      },
      getPageMes3 (obj) {
        this.detailFilter.current_page = obj.pageNumber
        this.detailFilter.page_size = obj.pageSize
        this.getDetailPageData()
      },
      getDetailPageData () {
        let tableData = JSON.parse(JSON.stringify(this.rowDetail['filters']))
        this.detailTableData = tableData.splice((this.detailFilter.current_page - 1) * this.detailFilter.page_size, this.detailFilter.current_page * this.detailFilter.page_size)
      },
      detailClose () {
        this.detailTableData = []
        this.detailFilter = {
          current_page: 1,
          page_size: 10
        }
      },
      addAttr () {
        this.addVisible = true
        this.isEdit = false
      },
      deleteTipClose () {
        this.showDeleteTip = false
      },
      async deleteAttr (row) {
        let res = await System.deleteAuthenticationCasRemove([row.userid])
        if (!res['code']) {
          this.$message.success('删除成功！')
          this.getAuthorizationUser()
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      },
      getPageMes (obj) {
        this.filter.current_page = obj.pageNumber
        this.filter.page_size = obj.pageSize
        this.getAuthorizationUser()
      },
      onTableChange (pagination, filters, sorter) {
        console.log('filters', filters)
        this.filter['category'] = filters['category'][0]
        this.filter.current_page = 10
        this.filter.page_size = 1
        this.getAuthorizationUser()
      },
      async getAuthorizationUser () {
        this.loading = true
        let newFilter = JSON.parse(JSON.stringify(this.filter))
        newFilter['schoolid'] = newFilter['schoolid'] + '%'
        let res = await System.getAuthenticationCasQuery(newFilter)
        this.loading = false
        if (!res['code']) {
          this.tabledata = res['data']
          this.total = res['total']
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
        let res2 = await System.getAuthenticationCasCount()
        if (!res2['code']) {
          this.countData = res2['data']
        } else {
          this.$message.error(this.errMsg[res2['code']])
        }
      },
      trunDepartmentData (data) {
        for (let k in data) {
          this.departmentName[data[k]['id']] = data[k]['name']
          if (data[k]['children']) {
            this.trunDepartmentData(data[k]['children'])
          }
        }
      },
      trunUsertypeData (data) {
        for (let k in data) {
          this.usertypeName[data[k]['id']] = data[k]['name']
          if (data[k]['children']) {
            this.trunUsertypeData(data[k]['children'])
          }
        }
      },
      async getAuthorizationCode () {
        let res = await System.getAuthenticationCode('basic')
        if (!res['code']) {
          for (let key in res['data']) {
            this.filters[key] = res['data'][key]
          }
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
        let res2 = await System.getAuthenticationCode('department')
        if (!res2['code']) {
          this.filters['department'] = res2['data']
          this.trunDepartmentData(res2['data'])
        } else {
          this.$message.error(this.errMsg[res2['code']])
        }
        let res3 = await System.getAuthenticationCode('usertype')
        if (!res3['code']) {
          this.filters['usertype'] = res3['data']
          this.trunUsertypeData(res3['data'])
        } else {
          this.$message.error(this.errMsg[res3['code']])
        }
        this.getAuthorizationUser()
      }
    },
    created () {
      this.getAuthorizationCode()
    },
    mounted () {
    }
  };
  </script>
  
  <style scoped>
  .content-bar {
    padding: 8px 0;
  }
  .log-page {
    padding: 20px 0;
  }
  
  .roles_item {
    display: inline-block;
    width: 140px;
    /*height: 20px;*/
    line-height: 30px;
    margin-bottom: -10px;
    overflow: hidden;
  }
  
  .current_roles_box {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .topsubmenu {
    padding-bottom: 0;
  }
  .coustom-title {
    color: rgba(0,0,0,0.85);
  }
  </style>
  